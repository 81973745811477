import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/bootsite/tippyjs.bootcss.com/website/src/components/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Why tooltips and popovers?`}</h3>
    <p>{`Both are elements positioned near a "reference" element, and are hidden until
they are triggered. They help conserve space by hiding secondary information or
functionality behind a hover or click. They are positioned outside the normal
flow of the document so when they are triggered, they are overlaid on top of the
existing UI without disrupting the flow of content.`}</p>
    <p>{`Tippy.js distinguishes them in the following way:`}</p>
    <ul>
      <li parentName="ul">{`A `}<strong parentName="li">{`tooltip`}</strong>{` is an element containing simple text content describing a
particular element. It's hidden until the user desires more information from
the element, e.g. before deciding to click a button.`}</li>
      <li parentName="ul">{`A `}<strong parentName="li">{`popover`}</strong>{` is an interactive HTML tooltip. It can be a dropdown, menu, or
any other kind of box that pops out from the normal flow of the document. This
type of element contains non-vital functionality and can be hidden behind a
click or hover to conserve space.`}</li>
    </ul>
    <p>{`Both of these are called a "tippy" when using Tippy.js!`}</p>
    <h3>{`Tippy.js`}</h3>
    <p><strong parentName="p">{`Size: 5.5 KB (core)`}</strong>{` (including Popper: 12.8 KB)`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong></p>
      <p parentName="blockquote">{`(core) means the core JS & CSS. If importing more themes, animations, plugins,
or addons, the size will increase.`}</p>
    </blockquote>
    <p>{`Tippy is an abstraction over Popper and provides a set of features and defaults
that make creating tooltip and popover elements easy.`}</p>
    <p>{`But, how does Tippy compare to other solutions?`}</p>
    <h3>{`Comparison with Popper.js`}</h3>
    <p><strong parentName="p">{`Size: 7.3 KB`}</strong></p>
    <p>{`Popper.js is a positioning engine, not a tooltip library. Popper's only goal is
to position an absolutely positioned element (the tooltip) near another element
(the reference).`}</p>
    <p>{`Since the element is absolutely positioned, naively centering it above the
reference element can cause it to:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Overflow the boundary`}</strong>{` (viewport, window, scrollParent) and therefore get
cut off`}</li>
      <li parentName="ul"><strong parentName="li">{`Overlap its reference element (due to overflow prevention)`}</strong>{`, so it should
flip to the opposite side`}</li>
      <li parentName="ul"><strong parentName="li">{`Detach from the reference element`}</strong>{` if inside a scrolling container`}</li>
    </ul>
    <p>{`Popper solves all of these problems. The expected logic to do this is very
complex, so this is effectively a "baseline" library if you want to even use
popper elements (tooltips, popovers, dropdowns) in your app in the first place
without them having poor UX.`}</p>
    <p>{`If you want to build the appearance and behavior of your popper elements from
scratch, this is a fantastic library. If you want "out of the box" (abstracted)
behavior, then using Tippy might be better.`}</p>
    <p>{`Tippy takes advantage of Popper as a dependency, so you can use them together
without incurring additional cost:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token module keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token class class-name"
          }}>{`Popper`}</span>{` `}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`popper.js`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`import`}</span>{` tippy `}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`tippy.js`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`If you're using the CDN, the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Popper`}</code>{` constructor will already be available.`}</p>
    <h3>{`Comparison with CSS tooltip libraries like Microtip or Balloon.css`}</h3>
    <p><strong parentName="p">{`Size: 1 KB`}</strong></p>
    <p>{`CSS tooltips can be tiny in size, but come with some drawbacks:`}</p>
    <ul>
      <li parentName="ul">{`Lack of positioning engine means overflow prevention & flipping are not
possible`}</li>
      <li parentName="ul">{`Interactivity can be complicated or inaccessible`}</li>
      <li parentName="ul">{`Using HTML content within them is cumbersome (especially with UI libraries
like React), with limited dynamism for updating content or reacting to state`}</li>
      <li parentName="ul">{`No dynamic arrow positioning or features like `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`followCursor`}</code></li>
    </ul>
    <h3>{`Comparison with Tooltipster`}</h3>
    <p><strong parentName="p">{`Size: 10 KB`}</strong>{` (including jQuery: 40 KB)`}</p>
    <p>{`Tooltipster is a fantastic library with very similar functionality, but requires
a jQuery dependency, unlike Tippy. To many people these days, this is a
deal-breaker! jQuery's minzipped size is about 30 KB, and Tooltipster including
CSS is about 10 KB. To people using frameworks like React, Vue, or Angular, it
can be hard to deal with such a large dependency.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      